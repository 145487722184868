<template>
	<!-- 会员中心 -->
	<div class="finished">
		<div class="project">
			<div class="query-area">
				<h3>竞价结果查看</h3>
				<el-form class="" :model="form" label-position="right" label-width="80px" inline ref="form">
					<el-form-item label="项目名称">
						<el-input style="width: 190px" v-model="form.xmmc" placeholder="项目名称"> </el-input>
					</el-form-item>
					<el-form-item label="产权类别">
						<el-select style="width: 190px" v-model="form.cqlx">
							<el-option
								v-for="item in typeOptions"
								:key="item.label"
								:label="item.label"
								:value="item.value"
							></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="行政区">
						<el-cascader
							style="width: 190px"
							v-model="form.xzqbh"
							:props="props"
							:options="regionOptions"
							:show-all-levels="false"
						></el-cascader>
					</el-form-item>
					<div class="button" style="display: inline-block; margin: 0">
						<el-form-item>
							<el-button class="query-btn" type="primary" @click="query">查询</el-button>
							<el-button class="query-btn" type="primary" @click="clear">清空</el-button>
						</el-form-item>
					</div>
				</el-form>
			</div>
			<p class="tip">
				参与项目结束后10分钟内，我们将向您发送站内信息，请及时查看并进行下一步操作。
			</p>
			<div class="table-con">
				<el-table
					border
					class="table"
					:data="tableData"
					style="width: 100%"
					:row-class-name="handleRowClass"
				>
					<el-table-column
						align="center"
						prop="xmbh"
						label="项目编号"
						width="120px"
					></el-table-column>
					<el-table-column
						align="center"
						prop="xmmc"
						label="项目名称"
						width="260px"
					></el-table-column>
					<el-table-column align="center" prop="jyfs" label="交易方式">
						<template slot-scope="scope">
							<span>{{ scope.row.jyfs ? scope.row.jyfs : '在线竞价交易' }}</span>
						</template>
					</el-table-column>
					<el-table-column align="center" prop="jjjzsj" label="结束时间"></el-table-column>
					<el-table-column align="center" prop="cjzj" label="最终出价"></el-table-column>
					<el-table-column align="center" prop="isSuccess" label="是否成交方">
						<template slot-scope="scope">
							<span v-if="scope.row.isSuccess == 0">否</span>
							<span v-else-if="scope.row.isSuccess == 1">是</span>
						</template>
					</el-table-column>
					<el-table-column align="center" prop="jjzt" label="竞价状态">
						<template slot-scope="scope">
							<span v-if="scope.row.jjzt == '0'">已发布</span>
							<span v-else-if="scope.row.jjzt == '1'">竞标中</span>
							<span v-else-if="scope.row.jjzt == '2'">竞标完成</span>
							<span v-else-if="scope.row.jjzt == '3'">流拍</span>
						</template>
					</el-table-column>
					<el-table-column align="left" label="操作" width="150">
						<template slot-scope="scope">
							<el-button
								style="width: 46px; height: 28px; padding: 0 8px"
								type="primary"
								size="small"
								@click="showDetail(scope.row)"
								>查 看</el-button
							>
							<el-button
								v-if="scope.row.isSuccess == 1"
								style="width: 70px; height: 28px; padding: 0 8px"
								type="success"
								size="small"
								@click="exportHt(scope.row)"
								>导出合同</el-button
							>
						</template>
					</el-table-column>
				</el-table>
			</div>
			<div class="pagenation">
				<el-pagination
					background
					layout="total, prev, pager, next"
					:current-page="currentPage"
					:page-size="pageSize"
					:total="total"
					@current-change="currentChange"
				>
				</el-pagination>
			</div>
		</div>

		<exportContract
			:visible="dialogVisible"
			:xmbh="selectRow.xmbh"
			@close="dialogClose"
			:contractInfo="contractInfo"
			:contractArray="contractArray"
		></exportContract>
	</div>
</template>

  <script>
import { mapGetters } from 'vuex'
import { getEndProInfo, getMyBiddingResult, viewContract, checkContract } from '@/api/projectManage'
import { getAreaTree } from '@/api/certificate'
import exportContract from '../components/exportContract.vue'
import { removeEmptyChildren } from "@/js/utils/util";
export default {
	components: {
		exportContract,
	},
	data() {
		return {
			form: {
				xmmc: '',
				cqlx: '',
				xzqbh: '',
			},
			typeOptions: [],
			regionOptions: [],
			props: {
				label: 'areaName',
				children: 'children',
				value: 'id',
				fullPathName: 'fullPathName',
				checkStrictly: true,
				emitPath: false,
			},
			tableData: [],
			currentPage: 1,
			pageSize: 10,
			total: 0,
			dialogVisible: false,
			contractInfo: null,
			contractArray: [],
			selectRow: {},
		}
	},
	computed: mapGetters(['userInfo']),
	created() {
		this.initData()
		this.getTypes()
		this.getRegions()
		if (this.$route.query.c) {
			this.showDetail({ xmbh: this.$route.query.c })
		}
	},
	methods: {
		async initData() {
			// 获取数据
			let params = Object.assign(this.form, {
				current: this.currentPage,
				size: this.pageSize,

			})
			let res = await getMyBiddingResult(params)
			let { data, status, message } = res
			if (status == 200) {
				this.tableData = data.records
				this.total = parseInt(data.total)
			}
		},
    // 条件查询
		query() {
			this.currentPage = 1
			this.initData()
		},
    // 清空查询条件
		clear() {
			this.currentPage = 1
			this.form = {
				xmmc: '',
				cqlx: '',
				xzqbh: '',
			}
			this.initData()
		},
		handleRowClass({ row, rowIndex }) {
			// 行樣式
			if (rowIndex % 2 === 0) {
				return 'odd'
			}
		},
		showDetail(row) {
			// 查看项目详情
			this.$router.push({ name: 'projectDetail', query: { c: row.xmbh } })
		},
    // 获取产权类型
		getTypes() {
			let cqList = JSON.parse(localStorage.getItem('types'))
			this.typeOptions = cqList.map(x => {
				return {
					label: x.lx,
					value: x.bh,
				}
			})
		},
		async getRegions() {
			// 获取行政区树
			let param = {
				level: '3',
				parentCode: null,
			}
			let res = await getAreaTree(param)
			let { code, data, msg } = res
			if (code == 0) {
				this.regionOptions = removeEmptyChildren(data);
			} else {
				this.$message.error(msg)
			}
		},
		//导出合同
		async exportHt(row) {
			this.selectRow = row
      console.log(row,'查看能否查看到类型')
      //cqlxbh产权类型编号
			if (row.currentIdentity == '3' && row.cqlxbh == '01') {
				let resCheck = await checkContract({ type: '0', xmbh: row.xmbh })
				let resCheck2 = await checkContract({ type: '1', xmbh: row.xmbh })
				if (resCheck.rel && resCheck2.rel) {
					let res1 = await viewContract({ type: '0', xmbh: row.xmbh })
					let res2 = await viewContract({ type: '1', xmbh: row.xmbh })
					this.contractArray = [res1, res2]
					this.dialogVisible = true
				} else if (!resCheck.rel) {
					this.$message.error(resCheck.message)
					return
				}
			} else {
				let resCheck = await checkContract({ type: '0', xmbh: row.xmbh })
				if (!resCheck.rel) {
					this.$message.error(resCheck.message)
					return
				}
				let res = await await viewContract({ type: '0', xmbh: row.xmbh })
				if (res) {
					this.contractInfo = res
					this.dialogVisible = true
				}
			}
		},
		dialogClose(val) {
			// 关闭弹窗
			this.dialogVisible = val
			this.initData()
		},
    // 切换页码
		currentChange(val) {
			this.currentPage = val
			this.initData()
		},
	},
}
</script>

  <style lang="less" scoped>
.query-area {
	padding: 0 30px;
}
.finished {
	text-align: left;
	height: 100%;
	position: relative;
	// margin-top: 46px;
	background: #fff;
	min-height: 900px;

	.s-title {
		.title {
			margin-left: 10px;
			font-size: 15px;
			font-weight: 600;
		}
	}
	.project {
		// padding-top: 38px;
		.table-con {
			margin-top: 12px;
			padding: 0 15px 0 18px;
		}
		.tip {
			padding-left: 20px;
			color: #e92f00;
			margin-bottom: 20px;
		}
	}
	.news {
		margin-top: 33px;
	}
	.pagenation {
		// margin-top: 0;
		// position: absolute;
		// right: 20px;
		// bottom: 33px;
		text-align: right;
	}
}
</style>

  <style lang="less">
.finished {
	.el-dialog__wrapper {
		position: absolute;
	}
	.v-modal {
		position: absolute;
	}
	.el-checkbox-button__inner {
		width: 160px;
		border-left: 1px solid #dcdfe6;
	}
	.el-dialog--center .el-dialog__body {
		padding: 25px 60px 30px 38px;
	}
	.el-checkbox-button.is-checked .el-checkbox-button__inner {
		border-radius: 5px;
		border: solid 1px #00a0e9;
		background: #fff;
		color: #00a0e9;
	}
	.el-dialog__footer {
		padding: 10px 20px 45px;
	}
}
</style>
